code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn{
  border-color: var(--theme-primary-background);
}

.ant-btn:focus, .ant-btn:hover {
  color: var(--font-white) !important;
  border-color: var(--theme-secondary-background) !important;
  background: var(--theme-secondary-background) !important;
}




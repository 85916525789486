@import 'styles/colors';

// common css
.d-flex {
  display: flex;
}
.align-item-center {
  align-items: center;
}
.vw-100 {
  width: 100vw;
}
.vh-100 {
  height: 100vh;
}
.f-15 {
  font-size: 15px !important;
}

.pointer-none {
  pointer-events: none;
}

.m-0 {
  margin: 0 !important;
}
.mt-50 {
  margin-top: 50px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-70 {
  margin-top: 70px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-30 {
  margin-right: 30px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-6 {
  margin-right: 6px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20{
  margin-left: 20px;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-7 {
  margin-left: 7px !important;
}
.p-20 {
  padding: 20px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10{
  padding-bottom: 10px;
}
.pl-10{
  padding-left: 10px;
}
.pl-15{
  padding-left: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.w-30 {
  width: 30% !important;
}
.w-45 {
  width: 45% !important;
}
.w-20 {
  width: 20% !important;
}

.w-128{
  width: 128% !important;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-align-center{
  text-align: center;
}

.text-align-end{
  text-align: end;
}

.ant-modal-close-x {
  margin: -15px -13px 0 0;
}

.ant-modal-content{
  border-radius: 10px !important;
}

.d-block{
  display: block !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.ant-spin-nested-loading>div>.ant-spin {
    top: 450px;
}

.ant-table-content{
  width: 100%;
  overflow: auto hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background:#5c5c5c;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.4);
}

.custom-link {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
}

.pt-60 {
  padding-top: 60px;
}